.DotSContainer{
    width: 100%;
    height: 100%;
}
.DotSbody{
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    padding-top: 100px;
}

.DotScard{
    width: 721px;
    height: 600px;
    text-align: center;
    align-items: center;
    background-color: rgb(212, 210, 210);
    margin-right:300px ;
    margin-left: 300px;
    margin-bottom: 200px;
    border-radius: 10px;
}
.DotH1{
    font-family: Lexend;
    color: #2B5989;
    padding-top: 20px;
}
.DotInputs{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.DotDotInput{
    width: 250px;
    height: 37px;
    border: 1px solid #2B5989;
    border-radius: 5px;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: rgba(23, 48, 74, 0.5);
    text-align: left;
    margin: 10px;
}
.DotDotRegister{
    width: 246px;
    height: 38px;
    border-radius: 5px;
    background-color: rgba(140, 199, 64, 1);
    border: none;
    margin: 25px;
    cursor: pointer;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}