.F2Container{
    width: 100%;
    height: 100%;
}
.F2body{
    width: 100%;
    height: 100%;
    align-items: center;
    font-family: Lexend;
    padding-top: 100px;
}
.F2card{
    width: 720px;
    height: 679px;
    border-radius: 10px;
    background-color: rgb(212, 210, 210);
    margin-left: 300px;
    margin-right: 300px;
    margin-bottom: 200px;
}
.F2H1{
    font-family: Lexend;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(23, 48, 74, 1);
    padding-top: 20px;

}
.F2input{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
}
.selection2{
    text-align: left;
    margin: 5px;
}
.label2{
    font-family: Lexend;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(23, 48, 74, 0.5);

}
.F2inputsign{
    width: 256px;
    height: 37px;
    border: 1px solid rgba(43, 89, 137, 1);
    border-radius: 5px;
    font-family: Lexend;

}
.F2button{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px;

}
.F2cancel{
    width: 245px;
    height: 38px;
    border-radius: 5px;
    border: none;
    background-color: rgba(43, 89, 137, 1);
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    cursor: pointer;
}
.F2save{
    width: 245px;
    height: 38px;
    border-radius: 5px;
    border: none;
    background-color:rgba(140, 199, 64, 1);
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    cursor: pointer;
}