.navigationBar {
  width: 100%;
  height: 72px;
  background-color: #17304A;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  width: 70%;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  padding-right: 50px;

}

/* Style the navigation links to be white */
.navigationButtons nav a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

/* Add an underline that eases in and out on hover */
.navigationButtons nav a:hover {
  border-bottom: 2px solid red;
}



.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  font-family: Lexend;
}

.dropdown-trigger {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(23, 48, 74, 1);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown.open .dropdown-content {
  display: block;
}

.nested-dropdown-content {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: rgba(23, 48, 74, 1);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
}

.dropdown li:hover .nested-dropdown-content {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px;
  position: relative;
}

li:hover {
  background-color: #8CC740;
  cursor: pointer;
}