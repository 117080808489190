.Fcontainer{
    width: 100%;
    height: 100%;
}
.FsignupBody{
    width: 100%;
    align-items: center;
    text-align: center;
    padding-top: 100px;
}
.FsignupCard{
    border-radius: 10px;
    background-color: rgb(212, 210, 210);
    margin-left: 300px;
    margin-right: 300px;
    font-family: lexend;
    height: 100%;
    padding: 22px 0;
}
.FsignupH1{
    font-family: Lexend;
    font-size: 20px;
    margin-top: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #2B5989;
    padding-top: 10px;
}
.Finput1{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    margin: 5px;
}
.Finput{
    width: 250px;
    height: 37px;
    border: 1px solid #2B5989;
    border-radius: 5px;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: rgba(23, 48, 74, 0.5);
    text-align: left;
}
.label{
    font-family: Lexend;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(23, 48, 74, 0.5);
    
}
.selection{
    text-align: left;
}
.Fbutton1{
    width: 246px;
    height: 38px;
    border-radius: 5px;
    background-color: rgba(140, 199, 64, 1);
    border: none;
    margin: 25px;
    cursor: pointer;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}
.InputContainer {
    display: flex;
    align-items: start;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 6px;
}
.ErrorMessage::before {
    content: '* ';
}
.ErrorMessage{
    font-size: 12px;
    color: red;
    margin-top: 4px;
}