.HomeScren{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: Lexend;
}

.homescreenBody{
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    padding-top: 100px;
}

.homeInfo{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
    margin-top: 10px;
}

.image{
    width: 40%;
    align-items: center;
    text-align:end;
    padding-right: 50px;
}

.infoTitle{
    font-family: Lexend;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(23, 48, 74, 1);
}
.infoDetails{
    display: flex;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(43, 89, 137, 1);

}

.Apps{
    width: 100%;
    height: 229px;
    background-color: rgba(140, 199, 64, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.appCard{
    width: 162px;
    height: 132px;
    background-color: white;
     border-radius: 10px ;
     text-align: center;
     align-items: center;
     margin: 10px;
     left: 361px;  
    cursor: pointer;
}

.appCard:hover {
    transform: scale(1.1);
  }
.appLabel{
    font-family: Lexend;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(23, 48, 74, 1);
}