
.content {
    display: flex;
    flex-direction: column;
    padding: 80px 20px;
}

.page-title {
    display: flex;
    align-items: start;
    font-size: 28px;
}