.login{
    width: 100%;
    height: 100%;
}

.loginBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: Lexend;
    padding-top: 100px;
}

.loginTitle{
    font-family: Lexend;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    color: #2B5989;
}

.formTitle{
    font-family: Lexend;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    color: rgba(43, 89, 137, 1);

}

.form{
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.input{
    width: 225px;
    height: 37px;
    border-radius: 5px;
    border: 1px solid rgba(43, 89, 137, 1);
    background-color: rgba(43, 89, 137, 0.1);
    margin: 10px;
}
.forgotPassword{
    font-family: Lexend;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: -8px;
    margin-left: 10px;
    color: rgba(23, 48, 74, 0.5);
    cursor: pointer;

}

.loginButton{
    width: 473px;
    height: 36px;
    border-radius: 5px;
    background-color: rgba(23, 48, 74, 1);
    font-family: Lexend;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    border: none;
    margin: 5px;
    margin-bottom: 10px;
    cursor: pointer;

}
.passinput{
    display: flex;
    flex-direction: row;
}